import './App.scss';
import PageTemplate from './Components/PageTemplate';

function App() {
  return (
      <>
        <PageTemplate />
      </>
  );
}

export default App;